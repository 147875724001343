import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
// import Navbar from "../../components/Header/Navbar";
import LandingPageContent from "../../components/LandingPageContent";
import Layout from "../../components/Layouts";
// import Footer from "../../components/Footer";

const LandingPage = () => {

    return (
        <div>
        <Layout >
            <LandingPageContent/>
        </Layout>
        </div>
    )
}
export default LandingPage    