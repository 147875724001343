
import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import global from './../../styles/global.module.scss';
import OGChumpImage from './../../assets/images/chump.gif';
import PFPChumpImage from './../../assets/images/p2.png';
import BuildingImage from './../../assets/images/building.gif'
import axios, { AxiosRequestConfig, AxiosResponse} from 'axios';
import SpecialTitle from "../SpecialTitle";



const CollectionsPageContent = () => {
    const CORS_PROXY_API = `https://curly-block-8983.globalhunter7277743.workers.dev?u=`; 
    const [bc_fp, setBCFP] = useState(null);
    const [bc_listed, setBCListed] = useState(null);
    const [pfp_fp, setPFPFP] = useState(null);
    const [pfp_listed, setPFPListed] = useState(null);

    useEffect(() => {
        axios.get(`${CORS_PROXY_API}https://api-mainnet.magiceden.dev/v2/collections/block_chumps/stats`)
            .then(function (response: any) {
                // handle success
                // console.log(response.data.collections);                
                // response?
                const nftsData = response?.data?.floorPrice;
                setBCFP(response?.data?.floorPrice);
                setBCListed(response?.data?.listedCount);
                console.log(JSON.stringify(nftsData));
                
            })
            .catch(function (error: any) {
                // handle error
                console.log(error);
            });
        axios.get(`${CORS_PROXY_API}https://api-mainnet.magiceden.dev/v2/collections/og_chump_pfps/stats`)
            .then(function (response: any) {
                // handle success
                // console.log(response.data.collections);                
                // response?
                const nftsData = response?.data?.floorPrice;
                setPFPFP(response?.data?.floorPrice);
                setPFPListed(response?.data?.listedCount);
                console.log(JSON.stringify(nftsData));
                
            })
            .catch(function (error: any) {
                // handle error
                console.log(error);
            });
    });

    const bc_floor = bc_fp?(bc_fp/1000000000):'?';
    const bc_listedCount = bc_listed?bc_listed:'?';
    const pfp_floor = pfp_fp?(pfp_fp/1000000000):'?';
    const pfp_listedCount = pfp_listed?pfp_listed:'?';

    return (
        <div className={styles.main_content}>
          
          <div className={styles.collections_area}>
            
          <div className={styles.collection_container}>
                <div className={styles.collection_title}><SpecialTitle>TBA</SpecialTitle></div>
                <div className={styles.collection_image}><img src={BuildingImage}></img></div>
                <div className={styles.collection_description}>Currently building. Announcement soon.</div>
                <div className={styles.buy_link}>
                    <a href="https://magiceden.io/marketplace/block_chumps">
                        Join the Discord for updates
                    </a>
                </div>
                <div className={styles.stats}>Mint & Whitelist TBA</div>
            </div>

            <div className={styles.collection_container}>
                <div className={styles.collection_title}><SpecialTitle>OG Chumps</SpecialTitle></div>
                <div className={styles.collection_image}><img src={OGChumpImage}></img></div>
                <div className={styles.collection_description}>1000 unique hand-animated chumps strolling the Solana sidewalks. 
                Retro-styled and giving no fucks. An OG Chump is also a license key for Chump NFT Engine. Sold-out public mint in Feb 2022.</div>
                <div className={styles.buy_link}>
                    <a href="https://magiceden.io/marketplace/block_chumps">
                        Explore current listings
                    </a>
                </div>
                <div className={styles.stats}>{'floor price: ' + bc_floor + ' SOL // listed: ' + bc_listedCount}</div>
            </div>

            <div className={styles.collection_container}>
                <div className={styles.collection_title}><SpecialTitle>PFP Chumps</SpecialTitle></div>
                <div className={styles.collection_image}><img src={PFPChumpImage}></img></div>
                <div className={styles.collection_description}>1000 static chump portraits, each one representing an edition from the OG Chumps collection. Private mint for OG Chump holders.</div>
                <div className={styles.buy_link}>
                    <a href="https://magiceden.io/marketplace/og_chump_pfps">
                        Explore current listings
                    </a>
                </div>
                <div className={styles.stats}>{'floor price: ' + pfp_floor + ' SOL // listed: ' + pfp_listedCount}</div>
            </div>

          </div>

        </div>
    )
}
export default CollectionsPageContent;  