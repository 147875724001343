import React, { useMemo, useEffect, useRef, useState } from "react";

import styles from './index.module.scss';
import PromoImage from './../../assets/images/colour_diamond.png'
import UIImage from './../../assets/images/Engine_UI.png'
import SpecialTitle from "../SpecialTitle";
import FeatureBox from "../FeatureBox";
import YoutubeEmbed from "../YoutubeEmbed";
import YouTube from 'react-youtube-embed';

// import ReactFullpage from '@fullpage/react-fullpage';

const EnginePageContent = () => {

    const aescriptsLink = "https://aescripts.com/chump-nft-engine/";
    const youtubeLink = "https://www.youtube.com/channel/UCrhWGv0OaBu6IQAWg5XK8IA/featured";
    const promoVideoLink = "6UBZx1ZUy4A";

    return (
            <>
                <div className={styles.main_content}> 
                    <div className={styles.title}><SpecialTitle>Chump NFT Engine</SpecialTitle></div>

                    <div className={styles.description}>
                        <p>A complete package for setting up and generating art & metadata for NFT collections directly from After Effects. </p>
                    </div>
                    
                    <div className={styles.btn_download_area}>
                            <button onClick={() => window.open(`${aescriptsLink}`)}>
                                <p className={styles.btn_download_content}>Get now</p>
                            </button>
                        <a href="\docs">
                        
                            <button>
                                <p className={styles.btn_download_content}>View docs</p>
                            </button>
                        </a>
                        <button onClick={() => window.open(`${youtubeLink}`)}>
                                <p className={styles.btn_download_content}>Tutorials</p>
                            </button>
                        <div className={styles.download_message}>
                        <p>Available to the public soon at <a href={aescriptsLink}>aescripts + aeplugins</a>.</p>
                        
                            <p>OG Chump holders can download the latest Chump version <a href="https://www.dropbox.com/s/6dn8tg642zskyu5/chump_nft_engine.zip?dl=1" download>here</a> (requires NFT & discord role to launch).</p>
                        </div>
                    </div>
            
                    <div className={styles.promo_image}><img src={UIImage}></img></div>
                    
                    <div className={styles.video}>
                        <YouTube id={promoVideoLink} />
                    </div>

                    <div className={styles.feature_grid}>
                        <FeatureBox 
                            title="Blockchain-ready multi-media NFTs" 
                            description="Export thousands of unique combinations as still-image, video or audio collections, along with blockchain-ready json metadata files for each." 
                            image={PromoImage}/>
                        <FeatureBox 
                            title="Get started in a few clicks" 
                            description="A simple tagging system lets you easily define traits and their options, as well as more complex setups if needed." 
                            image={PromoImage}/>
                        <FeatureBox 
                            title="Selection rules for greater control" 
                            description="A rule system allows you to control exactly how selections are made through probability and visual compatibility." 
                            image={PromoImage}/>
                        <FeatureBox 
                            title="Metadata made easy" 
                            description="A metadata editor makes setting up & updating your collection details quick and easy, and ensures you'll have all the information needed for the Ethereum or Solana networks." 
                            image={PromoImage}/>
                        <FeatureBox 
                            title="Advanced visual options" 
                            description="Add visual complexity to your setups with support for sub-selection variants and multi-layer traits." 
                            image={PromoImage}/>
                        <FeatureBox 
                            title="Fine tune your collection" 
                            description="Curate your collection by hand-creating custom editions that still adhere to the selection rules, and load existing editions for editing and adjustments." 
                            image={PromoImage}/>
                        <FeatureBox 
                            title="Endless potential" 
                            description="Push your collection to the limit with expressions, effects, simulations, masks, shapes and all the other bells and whistles offered by After Effects."
                            image={PromoImage}/>
                    </div>

                    <div className={styles.btn_download_area}>
                            <button onClick={() => window.open(`${aescriptsLink}`)}>
                                <p className={styles.btn_download_content}>Get now</p>
                            </button>
                        <a href="\docs">
                        
                        <button>
                            <p className={styles.btn_download_content}>View docs</p>
                        </button>
                    </a>
                    <button onClick={() => window.open(`${youtubeLink}`)}>
                            <p className={styles.btn_download_content}>Tutorials</p>
                        </button>
                        <div className={styles.download_message}>
                            To get started you'll need Adobe After Effects installed on your computer.
                        </div>
                    </div>
                    

                        
                </div>
                                
            </>

    )
}

export default EnginePageContent;