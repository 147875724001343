import React from "react";
import SpecialTitle2 from "../SpecialTitle2";
import styles from './index.module.scss';



const SettingAProject = (props: any) => {

    return (
        <div className={styles.section_area} id="settingaproject">
                <SpecialTitle2>
                Setting a Project
                </SpecialTitle2>

                <div className={styles.article}>
                <p>
                Once launched, the first thing you’ll want to do is set your project. Although this is not necessary at this point, it is recommended as the logical first step.
                </p>

                <div className={styles.indented_area}>
                    <ol>
                        <li>Click the “Set Project” button</li>
                        <p>This will launch a file browser.</p>
                        <li>Navigate to the directory you wish your files to be created in, or open a directory that already has an existing project</li>
                        <p>Note that the project folder is considered the root folder of your project. When starting a new project, 2 empty folders will be created within the project folder: “images” and “metadata”. If you select an existing project folder, CNE will check if any metadata files already exist, and this will determine if you are continuing a project or not. The existing project does not need to have images, only metadata.</p>
                        <li>Click “OK”</li>
                        <p>If you have selected a folder with existing collection metadata, that data will be loaded. Depending on the size of the existing collection, this can take a few seconds to complete.</p>
                    </ol>
                </div>
                
            </div>
        </div>
    );
}
export default SettingAProject;    




