import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
// import Navbar from "../../components/Header/Navbar";
import CollectionsPageContent from "../../../components/CollectionsPageContent";
import Layout from "../../../components/Layouts";
// import Footer from "../../components/Footer";

const CollectionsPage = () => {

    return (
        <div>
        <Layout >
            <CollectionsPageContent/>
        </Layout>
        </div>
    )
}
export default CollectionsPage    