import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import DocsNav from './../../../components/DocsContent/DocsNav';
import DocsMain from '../../../components/DocsContent/';
import Navbar from "../../../components/Header/Navbar";
import Footer from "../../../components/Footer";

const DocsPage = () => {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'auto'})
      }, [])
    return (
        <>
        <Navbar />
        <div className={styles.container} id="docs">
            <DocsNav />
            <DocsMain />
        </div>
        <Footer />
        </>
    )
}

export default DocsPage;