// components/layout.js

import { ReactNode } from "react";
import styles from './index.module.scss';

interface Props {
    children?: ReactNode;
    title: string;
    description: string;
    image: string;
    // any props that come into the component
}

export default function FeatureBox(Props : Props) {
  
  return (
    <>
    <div className={styles.feature_box}>
        <img src={Props.image}></img>
        <div className={styles.feature_text}>
          <div className={styles.feature_title}>{Props.title}</div>
          <div className={styles.feature_description}>{Props.description}</div>
        </div>
    </div>
    </>
  )
}