import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import { Link } from "react-router-dom";

import EngineImg from './../../../assets/images/engine.png';

const DocsNav = (props: any) => {

    return (
        <div className={styles.docsnav}>
            {/* 
            <div className={styles.docsnav_logo}>
                <img src={EngineImg} alt="engine" />
            </div>
            */}
            <div className={styles.docsnav_link_area}>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#introduction"> Introduction </a> </div>
                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}><a href="#gettingstarted">Getting Started</a></div>
                        <div className={styles.docsnav_sublink}> <a href="#installation"> Installation </a> </div>
                        {/*<div className={styles.docsnav_sublink}> <a href="#loggingin"> Logging in (Chump holders) </a> </div>*/}
                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}><a href="#thebasics">The Basics</a></div>
                        <div className={styles.docsnav_sublink}> <a href="#settingtheproject"> Set the project </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#editingthemetadata"> Edit the metadata </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#definingthelayers"> Define the traits </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#definerules"> Add some rules </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#previewingthegeneration"> Build some editions </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#exportingthecollection"> Export the collection </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#updatingthecollection"> Update the collection </a> </div>
                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#settingaproject"> Setting a project </a> </div>
                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#editingmetadata"> Metadata </a> </div>
                    {/* 
                        <div className={styles.docsnav_sublink}> <a href="#fields"> Fields </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#editingnewmetadata"> New metadata </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#editingexistingmetadata"> Existing metadata </a> </div>
                    */}

                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#layers"> Layers & tags </a> </div>
                    <div className={styles.docsnav_sublink}> <a href="#generalstructure"> General Structure </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#traitlayers"> Trait Layers </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#selectionlayers"> Selection Layers </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#linkedlayers"> Linked Layers </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#variantlayers"> Variant Layers </a> </div>
                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#selectionrules"> Selection Rules </a> </div>
                    <div className={styles.docsnav_sublink}> <a href="#selectionorder"> Selection Order </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#chance"> Chance </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#require"> Require </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#avoid"> Avoid </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#trigger"> Trigger </a> </div>
                </div>

                {/* 
                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#singleeditionassembly"> Single Edition Assembly </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#custombuild"> Custom build</a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#autobuild"> Auto build</a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#rebuildexisting"> Rebuild existing</a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#exporting"> Exporting </a> </div>
                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#exportingeditions"> Exporting editions </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#1by1"> 1 by 1</a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#batchexport"> Batch export</a> </div>
                </div>
                */}
                
                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#preferences"> Preferences </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#generationprefs"> Generation </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#renderformat"> Render format </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#linkedlayerprefs"> Linked Layers </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#generalprefs"> Render Comp </a> </div>
                </div>

                <div className={styles.docsnav_link_group}>
                    <div className={styles.docsnav_link}> <a href="#extrautilities"> Extra Utilities </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#layerstructuretemplate"> Layer structure template </a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#matchexistingcollection"> Match existing collection</a> </div>
                        <div className={styles.docsnav_sublink}> <a href="#exportstatisticssonly"> Generate stats only </a> </div>
                </div>

            </div>
        </div>
    )
}
export default DocsNav;    




