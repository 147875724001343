import React from "react";
import styles from './index.module.scss';

import ButtonsImg1 from './../../assets/images/docs/extras.png';
import SpecialTitle2 from "../SpecialTitle2";

const ExtraUtilities = (props: any) => {

    return (
        <div className={styles.section_area} id="extrautilities">
            <SpecialTitle2>
            Extra Utilities
                </SpecialTitle2>
            <div className={styles.image_area}>
                <img src={ButtonsImg1} alt="generator" />
            </div>
            <div className={styles.article}>
            <h1 id="layerstructuretemplate">
                Layer structure template
                </h1>
                <p>
                Use this button to quickly build a layer structure that you can then bring your artwork into.
               </p>
                <h1 id="matchexistingcollection">
                Match existing collection
                </h1>
                <p>
                This button will prompt you to load an existing collection's metadata, and will then take the edition-specific metadata (ie trait combinations) and attempt to export identical combinations using your current project. In simple terms, you can export your collection using another collection's trait combinations.
                </p>
                <h1 id="exportstatisticsonly">
                Export statistics only
                </h1>
                <p>
                This button will export only statistical data (rankings & trait spreadsheet) from the already loaded metadata. It will not generate any new editions and does not require an After Effects project be opened.
                </p>
            </div>
        </div>
    );
}
export default ExtraUtilities;    




