import React from "react";
import styles from './index.module.scss';

import RulesImg from './../../assets/images/docs/selection_rules.png';
import SpecialTitle2 from "../SpecialTitle2";

const SelectionRules = (props: any) => {

    return (
        <div className={styles.section_area} id="selectionrules">
                <SpecialTitle2>
            Selection Rules
                </SpecialTitle2>

                <div className={styles.article}>
                    
                    <p>
                    Selection rules determine how CNE chooses specific “Selection” layers to display within each Trait. This forms the basis of how your editions will be built across your collection, and allows for complex criteria. 
                    </p>

                    <div className={styles.image_area}>
                        <img src={RulesImg} alt="rules" />
                        <div className={styles.image_area_description}>Selection rules window</div>
                    </div>

                    <h1 id="selectionorder">
                        Selection order
                    </h1>

                    <p>
                        Chump NFT Engine uses a cascading approach to layer selection. It goes down the Trait layers from top to bottom and makes selections in that order. This affects how the Require and Avoid rules work, in that they can only refer to Traits that are selected previously. The Chance and Trigger rules work regardless.
                    </p>

                    <div className={styles.tip_area}>
                    The order of selection doesn’t necessarily need to be the order in which you want the layers viewed. Refer to the video tutorials for an explanation.
                    </div>
                    
                </div>

                <div className={styles.article}>
                    <h1 id="chance">Chance</h1>
                    <p>
                    Every Selection layer has a Chance value, whether set explicitly or not. By default each layer will have the same Chance value (10), meaning that each layer has an equal chance of being selected.
                    </p>
                    <p>
                    By adjusting the Chance value manually, you can control the rarity of each Selection to give your collection the desired balance. A Selection with a Chance value of 1 will be much rarer than a Selection with a Chance value of 200. 
                    </p>
                    <p>
                    It’s important to note that the Chance values of layers are relative, and must be integers. You cannot set a Chance value lower than 1, but you can make 1 rarer by increasing the Chance value of the other Selections.
                    </p>

                </div>

                <div className={styles.article}>
                    <h1 id="require">Require</h1>
                    <p>
                    When adding a Require rule, you are telling the current selection to require a particular selection on a previous trait. For example, you may have a Trait called “Hat” and a Trait called “Hairstyle”. The Hat Trait has selections which only work with a certain Hairstyle Trait. 
                    </p>
                    <p>
                    A Require rule can include multiple Traits, as well as multiple Selections in each Trait. For example:
                    </p>
                    <div className={styles.indented_area}>
                        <p>
                        The Hat Selection of Bandana Requires Hairstyle to be set to Short OR Bald <br/>
                        The Hat Selection of Bandana also Requires Earwear to be set to None.
                    </p>
                    </div>

                </div>

                <div className={styles.article}>
                    <h1 id="avoid">Avoid</h1>
                    <p>
                    Avoid rules work the same way as Require rules, except that you are telling CNE all of the traits and selections that must be avoided in order to consider the current Selection. 
                    </p>
                    <p>
                    In the example picture above, the Hairstyle Selection of Rockabilly will not be valid if the Headwear Selection contains any of the keywords Hat, Crown, Helmet, Bandana, or Cap. In addition to this, it will also be invalid if the Earwear Selection contains the keyword Headphones.
                    </p>

                </div>

                <div className={styles.article}>
                    <h1 id="trigger">Trigger</h1>
                    <p>
                    A Trigger rule works in conjunction with Variant Layers. Once you define some Variant Layers, they will be available to the Trigger rule.
                    </p>
                    <p>
                    Triggers allow you to tell a certain Variant to display if this current Selection is chosen. For example, you have a Trait called "Skin Tone", and one of the Selections is called "Blue Skin". You can set Blue Skin to trigger the Variant called "Blue" (assuming you have one) to display throughout your edition.
                    </p>

                </div>
            </div>
    );
}
export default SelectionRules;    




