import React, { useState } from "react";
import styles from './index.module.scss';
import Logo from './../../../assets/images/logo3.png';
import ClickAwayListener from 'react-click-away-listener';
import { useResize } from "./../../../utils/Helper";

const Navbar = (props: any) => {
    const [isOpen, SetisOpen] = useState(false);
    const ToggleMenu = () => {
        SetisOpen(!isOpen);
    }

    const handleClickAway = () => {
        SetisOpen(false);
    }

    const {isMobile, isResponsive} = useResize();

    return (
        

        <>
        {!isResponsive
        ?<div className={styles.navbar}>

        <div className={styles.linkarea}>
            
            <div className={styles.logo}>
                <a href="/dashboard"><img src={Logo}></img></a>
            </div>

            <div className={styles.textlinks}>
                <a className={styles.link} href="/collections">Collections</a>
                <a className={styles.link} href="/chumpnftengine">NFT Generator</a>
            </div>
        </div>
        </div>
        :
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.navbar}>
                <div className={styles.linkarea}>

                    <div className={styles.logo}>
                        <a href="/dashboard"><img src={Logo}></img></a>
                    </div>
                    <a onClick={ToggleMenu}>
                        <i className="fa fa-bars" style={{marginTop:'0px', marginRight: '20px', fontSize: '30px', color: 'white'}}></i>
                    </a>
                        {isOpen? 
                        <div className={styles.navMenu}>
                            <div className={styles.textlinks}>
                            <a className={styles.link} href="/collections">Collections</a>
                            <a className={styles.link} href="/chumpnftengine">NFT Generator</a>
                        </div>
                        </div>
                        :
                        <div></div>
                        }
                </div>
            </div>
        </ClickAwayListener>
        }
        </>

    )
}
export default Navbar;    




