import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import MiniLogo from './../../assets/images/logo3.png';
import TwitterLogo from './../../assets/images/twitter.png';
import DiscordLogo from './../../assets/images/discord.png';

const Footer = () => {

    const twitterLink = `https://twitter.com/blockchumps`;
    const discordLink = `https://discord.gg/Zjh5Q8NYc9`;
    return (
        <div className={styles.footer}>
            <div className={styles.copyright}>
                copyright Block Chumps
            </div>
            <div className={styles.social_section}>
            <div className={styles.footer_twitter} onClick={() => window.open(`${twitterLink}`)}>
                <img src={TwitterLogo } ></img>
                </div>
                <div className={styles.footer_twitter} onClick={() => window.open(`${discordLink}`)}>
                <img src={DiscordLogo}></img>
                </div>
            </div>
        </div>
    )
}
export default Footer;    