// components/layout.js

import { ReactNode } from "react";
import styles from './index.module.scss';
import Image from './../../assets/images/colour_square.png';
interface Props {
    children?: ReactNode
    // any props that come into the component
}

export default function SpecialTitle({ children, ...Props }: Props) {
    
  return (
    <>
    <div className={styles.special_title}>
        <img src={Image}></img>
        <div className={styles.special_title_text}>{children}</div>
    </div>
    </>
  )
}