import React from "react";
import SpecialTitle2 from "../SpecialTitle2";
import styles from './index.module.scss';
import MetadataImg from './../../assets/images/docs/metadata_window.png';



const EditingMetadata = (props: any) => {

    return (
        <div className={styles.section_area} id="editingmetadata">
                <SpecialTitle2>
                Editing Metadata
                </SpecialTitle2>

                <div className={styles.article} id="fields">
                <p>
                Every NFT holds metadata that describes the NFT contents along with the collection 
                it's a part of. The attribute metadata (that describes the trait combination) is 
                generated automatically and will be unique to each NFT, but the general collection 
                metadata will be identical across all editions and must be filled out manually.
                </p>

                <h1>
                    Filling out the various fields
                </h1>
                <p>
                There's a bunch of fields, so let's go through each one.
                </p>

                <div className={styles.image_area}>
                    <img src={MetadataImg} alt="metadata" />
                    <div className={styles.image_area_description}>Metadata window</div>
                </div>
                
                <div className={styles.indented_area}>
                    <ul>
                        <li> Collection name </li>
                        <p>The name of your collection (eg. Cool Dogs)</p>
                        <li> Collection family </li>
                        <p>The name of the wider collection that this collection belongs to, if any. (eg. Cool Animals)</p>
                        <li> Collection symbol </li>
                        <p>An optional letter symbol for your collection, up to 10 characters long.</p>
                        <li> Description </li>
                        <p>The description of your collection</p>
                        <li> Website </li>
                        <p>Any URL you want to include</p>
                        <li> Creator Wallet </li>
                        <p>The wallet to receive secondary sale royalties after minting</p>
                        <li> Seller commission </li>
                        <p>The secondary sale royalty percentage</p>
                        <li> Custom Base URI </li>
                        <p>The base URI where your images will be stored (such as on IPFS). If you're using a tool to upload your collection (ie. Candy Machine) you can leave this blank as the tool will likely handle this for you.</p>
                        <li> Specify final image format </li>
                        <p>After Effects is limited in what it can output, so if you know that you'll be converting your images to a different format outside of After Effects (eg MP4, GIF), you should select that format here so that the metadata properly reflects it. Leaving this on Auto will use the format selected within Chump NFT Engine.</p>
                    </ul>
                </div>
                
            </div>

            <div className={styles.article} id="editingexistingmetadata">
                <h1>
                    Editing metadata for an existing project
                </h1>
                <p>
                    When you load an existing project, the metadata fields will be soft-locked.
                You can update the metadata at any point by unlocking the fields, however it's important to remember 
                to check the "Update existing" checkbox. Doing this will ensure all previously exported metadata 
                files will reflect your changes. If you leave this unchecked only new metadata files will reflect the changes.
                </p>
                
            </div>

            

        </div>
    );
}
export default EditingMetadata;    




