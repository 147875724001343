import React from "react";
import SpecialTitle2 from "../SpecialTitle2";
import styles from './index.module.scss';



const GettingStarted = (props: any) => {

    return (
        <div className={styles.section_area} id="introduction">
                <SpecialTitle2>
                Introduction
                </SpecialTitle2>

            <div className={styles.article}>
                <h1>
                    What is it?
                </h1>
                <p>
                At its core, <span className={styles.keyword}>Chump NFT Engine</span> is an extension for Adobe After Effects that takes your artwork and outputs a collection of thousands of unique combinations. It’s designed to create NFT collections, and therefore also outputs crucial metadata files for each combination, as well as useful statistics such as rarity rankings. Although geared towards NFTs, it can be used to produce any kind of art collection that would benefit from algorithmically generated combinations.</p>
                <p>
                What makes Chump NFT Engine appealing to artists is that it’s built natively on top of an extremely powerful graphics software, After Effects. Anything that you can do in After Effects can be incorporated into your NFTs, including but not limited to animation, compositing, simulation effects such as particles, masking and sound.
                </p>
                <p>
                Chump NFT Engine was created in conjunction with the NFT project Block Chumps, and was used to create the animated collection 'OG Chumps'. The tool will be continuously expanded to add requested functionality and quality of life improvements, with critical feedback is encouraged to make this as good as it can be. It is hoped that Chump NFT Engine can inspire artists to create unique NFT collections and help grow the NFT space artistically.
                </p>
                
            </div>
        </div>
    );
}
export default GettingStarted;    




