import React from "react";
import styles from './index.module.scss';

import LoginImg from './../../assets/images/docs/image9.png';
import CodeImg from './../../assets/images/docs/image4.png';
import SelRuleBtnImg from './../../assets/images/docs/selection_rules_btn.png';
import SelRuleWindowImg from './../../assets/images/docs/selection_rules.png';
import SetTraitImg from './../../assets/images/docs/set_trait_btn.png';
import SetSelImg from './../../assets/images/docs/set_selection_btn.png';
import MetadataImg from './../../assets/images/docs/metadata_window.png';
import MetadataBtn from './../../assets/images/docs/edit_metadata_btn.png';
import TemplateImg from './../../assets/images/docs/image8.png';
import ProjectImg from './../../assets/images/docs/set_project_btn.png';
import ManualBuildImg from './../../assets/images/docs/custom_build_section.png';
import AutoBuildImg from './../../assets/images/docs/auto_build.png';
import AddToCollectionImg from './../../assets/images/docs/add_to_collection.png';
import EditionCountImg from './../../assets/images/docs/number_of_editions.png';
import BatchImg from './../../assets/images/docs/start_generating.png';
import PreferencesImg from './../../assets/images/docs/preferences.png';
import SpecialTitle2 from "../SpecialTitle2";

const ASimpleCollection = (props: any) => {

    return (
        <div className={styles.section_area} id="thebasics">
            <SpecialTitle2>
            The Basics
                </SpecialTitle2>

                

            <div className={styles.article} id="basicprefs">
            <p>
                The following explains the basic process from start-to-finish for building a simple collection.
                </p>
                
            <h1>
                    Preferences
                </h1>

                <p>Before starting, let's take a quick look at the Chump NFT Engine preferences. The only important setting to take note of for now is the render format. This is where you'll define what type of files you'll be exporting. For more information on each type, see <a href='#renderformat'>Render Format</a>.
                </p>
                <div className={styles.image_area}>
                    <img src={PreferencesImg} alt="project" />
                </div>
            </div>

            <div className={styles.article} id="settingtheproject">
            

                <h1>
                    1. Set the project
                </h1>
                <div className={styles.image_area}>
                    <img src={ProjectImg} alt="project" />
                    <div className={styles.image_area_description}>Set project button</div>
                </div>
            
                <p>
                Once launched, the first thing you’ll want to do is set your project. Although this is not necessary at this point, it is recommended as the logical first step.
                </p>

                <div className={styles.indented_area}>
                    <ol>
                        <li>Click the folder icon in the top left to open your system file browser.</li>
                        <li>Select a folder for your collection.</li>
                        <li>Click OK.</li>
                    </ol>
                </div>
                
            </div>




            <div className={styles.article} id="editingthemetadata">
                <h1>
                    2. Fill in the collection-wide metadata
                </h1>
                <div className={styles.image_area}>
                    <img src={MetadataBtn} alt="metadata" />
                    <div className={styles.image_area_description}>Edit metadata button</div>
                </div>
                <p>   
                    Clicking the metadata button opens up a form where you can enter the main collection metadata 
                    such as title, description, sales commission etc. If you have loaded an existing collection, 
                    these fields will be filled by the existing metadata.
                </p>
                <div className={styles.indented_area}>
                    <ol>
                        <li>Click the metadata icon in the top right to open the metadata editor.</li>
                        <li>Fill in your collection details.</li>
                        <li>Click OK.</li>
                    </ol>
                </div>
                <div className={styles.image_area}>
                    <img src={MetadataImg} alt="metadata" />
                    <div className={styles.image_area_description}>Metadata window</div>
                </div>
                
            </div>


            <div className={styles.article} id="definingthelayers">
                <h1>
                3. Define the Traits and their Selections
                </h1>

                <div className={styles.image_area}>
                    <img src={SetTraitImg} alt="metadata" />
                    <div className={styles.image_area_description}>Trait button</div>
                </div>

                <p>   
                    Select the layers you would like to be Traits, and click the Trait tag button. All layers inside 
                    the Trait layers will be automatically tagged as Selections. You can always go in and untag layers later, or tag additional layers.
                </p>

                <div className={styles.indented_area}>
                    <ol>
                        <li>In your main composition, select layers that you want to be Traits</li>
                        <p>Note: The Trait layers need to be compositions, as they hold your Selection layers</p>
                        <li>Click the "Trait" icon under the  "Layer setup/tagging" section to tag the layer</li>
                        <p>Notice your Traits now appear in the Chump NFT Engine UI under the "Edition Assembly" section.</p>
                        <li>Add layers inside your Trait layer to act as the different selections, and tag them using the Selection button</li>
                        <p>Note: Any layers already inside your Trait layer have been automatically tagged as Selections</p>
                    </ol>
                </div>
                <div className={styles.image_area}>
                    <img src={SetSelImg} alt="metadata" />
                    <div className={styles.image_area_description}>Selection button</div>
                </div>
                
            </div>

            <div className={styles.article} id="definerules">
                <h1>
                4. Define some selection rules
                </h1>
                <div className={styles.image_area}>
                    <img src={SelRuleBtnImg} alt="metadata" />
                    <div className={styles.image_area_description}>Selection rules button</div>
                </div>
                <div className={styles.image_area}>
                    <img src={SelRuleWindowImg} alt="metadata" />
                    <div className={styles.image_area_description}>Selection rules window</div>
                </div>
                <p>   
                    Selection Rules allow you to control how Selections are made for each Trait. You can setup complex rules, but for now we will just focus on setting the probability for each Selection.
                </p>

                <div className={styles.indented_area}>
                    <ol>
                        <li>Click the 'Selection Rules' button to open the Selection Rule editor.</li>
                        <p>Note: The Trait layers need to be compositions, as they hold your Selection layers</p>
                        <li>Select a Trait to see the list of Selections available.</li>
                        <li>Select one to edit the rules for how that Selection is chosen.</li>
                        <li>Edit the 'Chance' slider to adjust the probability of that Selection being chosen.</li>
                        <p>The values are relative to the other Selections within that Trait</p>
                        <li>Click 'Commit' to save the changes before moving on to edit other Selections.</li>
                        <li>Repeat for other Traits & Selections</li>
                    </ol>
                </div>
                
            </div>

            <div className={styles.article} id="previewingthegeneration">
                <h1>
                5. Build and export an edition
                </h1>
                <p>   
                    You can now start building editions based on your setup using 2 methods.
                    </p>
                    <p>   
                    To build an edition manually, Trait-by-Trait, do the following:
                    </p>
                    <div className={styles.indented_area}>
                   <ul>
                    <li>In the 'Edition Assembly' section, change the Selections for each Trait using the drop-down list.</li>
                    <p>As you make selections here, After Effects will reflect the changes and show your edition in its current state. </p>
                    </ul>
                    </div>
                    <div className={styles.image_area}>
                    <img src={ManualBuildImg} alt="metadata" />
                </div>
                
                <p>
                    To have an edition randomly built, do the following:
                </p>
                <div className={styles.indented_area}>
                   <ul>
                        <li>Click the 'Auto-build' button in the bottom left to randomly make the selections.</li>
                        <p>You can still manually change some Traits after using the auto-build button.</p>
                    </ul> 
                </div>
                <div className={styles.image_area}>
                    <img src={AutoBuildImg} alt="metadata" />
                </div>
                
                <p>Once you're happy, you can add this edition to your collection:</p>
                <div className={styles.indented_area}>
                    <ul> 
                        <li>click the "Add to Collection" button in the bottom right.</li>
                    </ul> 
                </div>
                <div className={styles.image_area}>
                    <img src={AddToCollectionImg} alt="metadata" />
                </div>
                <p>
                    After Effects will then render your image and create the associated json metadata file.
                    Once finished, notice that at the top of the UI, under your collection name, the editon count should now say 1. Build and add as many as you'd like before moving on.
                </p>
                
            </div>

            <div className={styles.article} id="exportingthecollection">
                <h1>
                6. Batch export many editions
                </h1>
                <p>   
                Ultimately you'll want to batch export many editions, rather than making them 1 by 1. Do the following:</p>
                
                <div className={styles.indented_area}>
                    <ol> 
                        <li>In the text box, enter the number of editions to add.</li>
                        <div className={styles.image_area}>
                    <img src={EditionCountImg} alt="metadata" />
                    </div>
                        <li>Click the 'Start Generating' button.</li>
                        <div className={styles.image_area}>
                    <img src={BatchImg} alt="metadata" />
                </div>
                    </ol> 
                </div>
                
                
                <p> After Effects may appear unresponsive as it renders your editions, but you can follow the file-production progress in your OS file browser.</p>
                
            </div>

            <div className={styles.article} id="updatingthecollection">
                <h1>
                7. Update an existing collection
                </h1>
                <p>   
                    You can update any existing collection by setting the project to the collection folder, and then proceeding as usual by generating more editions. 
                </p>
                <p> 
                    You can also update the general metadata if desired, but there's a couple of things to note: 
                </p>
                <div className={styles.indented_area}>
                    <ul> 
                        <li>The fields will be 'soft-locked' by default, but you can unlock them by clicking the lock icon. </li>
                        <li>The "Update existing" checkbox should be checked to ensure all previously created metadata files also reflect the changes. </li>
                    </ul> 
                </div>
                    
                
            </div>

            
        </div>
    );
}
export default ASimpleCollection;    




