import React from 'react';
import {  BrowserRouter,  Routes,  Route } from "react-router-dom";
// css standards import
import './styles/normalize.css';
import './styles/utils.css';
import './styles/app.css';

import LandingPage from './pages/LandingPage';
import EnginePage from './pages/SubPages/EnginePage';
import StakingPage from './pages/SubPages/StakingPage';
import DocsPage from './pages/SubPages/DocsPage';
import CollectionsPage from './pages/SubPages/CollectionsPage';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/dashboard" element={<LandingPage />} />
            <Route path="/collections"  element={<CollectionsPage />} />
            <Route path="/chumpnftengine" element={<EnginePage />} />
            <Route path="/staking" element={<StakingPage />} />
            <Route path="/docs" element={<DocsPage />}/>
            <Route path="/" element={<LandingPage />} />
            <Route path="/*" element={<LandingPage />} />
            
          </Routes>  
        </BrowserRouter>
        
    </div>
  );
}

export default App;
