import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import { Link } from "react-router-dom";

import Introduction from './introduction';
import GettingStarted from './gettingstarted';
import Layers from './layers';
import SelectionRules from './selectionrules';
import Preferences from './preferences';
import ExtraUtilities from './extrautilities';
import SpecialTitle from "../SpecialTitle";
import ASimpleCollection from "./asimplecollection";
import SettingAProject from "./settingaproject";
import EditingMetadata from "./editingmetadata";
import SingleEditionAssembly from "./singleeditionassembly";
import ExportingEditions from "./exportingeditions";

const DocsMain = (props: any) => {

    return (
        <div className={styles.docscontent}>
            <div className={styles.title_area}>
                <SpecialTitle>Chump NFT Engine</SpecialTitle>          
                <div className={styles.title_area_subtitle}>
                    Documentation
                </div>
            </div>
            <div className={styles.tip_area}>
                This guide will be continuously updated to be more comprehensive, as well as to include links to youtube tutorials. Please get in touch with any suggestions for improvement!
            </div>
            
            <Introduction />
            <GettingStarted />
            <ASimpleCollection />
            <SettingAProject />
            <EditingMetadata />
            <Layers />
            <SelectionRules />
            <Preferences />
            <ExtraUtilities />

        </div>    
    );
}
export default DocsMain;    




