import React from "react";
import styles from './index.module.scss';

import PrefsImg from './../../assets/images/docs/preferences.png';

import GeneratorImg from './../../assets/images/docs/image2.png';
import FormatImg from './../../assets/images/docs/image7.png';
import SpecialTitle2 from "../SpecialTitle2";

const Preferences = (props: any) => {

    return (
        <div className={styles.section_area} id="preferences">
                <SpecialTitle2>
            Preferences
                </SpecialTitle2>

                <div className={styles.image_area}>
                        <img src={PrefsImg} alt="generator" />
                    </div>

                <div className={styles.article}>
                    <h1 id="generationprefs">
                        Generation
                    </h1>
                    <p>
                    Define the generation settings and the format to export.
                    </p>
                    <div className={styles.indented_area}>
                        <ul>
                             <li>Maximum number of attempts at finding a unique set of traits</li>
                            <p>This represents how many failed attempts can be made at finding a unique combination before generation is stopped.
                                The number depends on your collection, how many traits you have, how unique you want each 
                                edition to be, and how many editions you want to make. 1000 is a good starting point.</p>
                            <li>Number of unique traits required for each edition</li>
                            <p>This number defines how many traits must be different in order for the edition to be considered unique. 
                                For example, leaving this at the default value of 1 ensures every edition is different by at least 1 trait. The higher the number, 
                                the harder it will be to find a unique combination, but the results will appear more unique. Setting this to 0 will not enforce any uniqueness across editions, 
                                while setting this to a number higher than the number of traits you have will result is no editions being generated.</p>
                            <li>Variable</li>
                            <p>This toggle sets the above uniqueness value to be variable. That means if you have a high uniqueness value, 
                                but the maximum number of attempts are reached without finding a new edition, the uniqueness value will lower by 1 and the generation will continue.
                                This is simply a way to keep the generation running as long as possible while also getting the most unique combinations possible.</p>
                        </ul>
                    </div>

                    <p>
                    Depending on your collection needs, these values will need to be adjusted in relation to each other to ensure you get the result you like. 
                    Keep in mind there is always a maximum number of unique combinations possible to any collection.
                    </p>
                </div>

                <div className={styles.article}>
                    <h1 id="renderformat">Render format</h1 >
                    <p>There are 3 main formats for exporting your NFTs:
                    </p>
                    <div className={styles.indented_area}>
                        <ul>
                            <li>MOV</li>
                            <p>Used for anything animated.</p>
                            <li>PNG</li>
                            <p>Used for still-image collections. The first frame of your Render comp will be the frame that gets rendered.</p>
                            <li>WAV</li>
                            <p>For sound-only</p>
                        </ul>
                    </div>
                    <p> You can either create output modules manually with these names, or import the set provided with the tool in the 'extras' folder. Either way, you must have output modules with those names in order to use them.</p>

                    <p> There are 2 additional Render Format options that can be useful:</p>

                    <div className={styles.indented_area}>
                        <ul>
                            <li>Render using AME</li>
                            <p>Selecting “Render using AME” will send your data to Adobe Media Encoder (if you have it), and use the most recent format 
                            settings that you have set up within that. Adobe Media Encoder allows for extra formats not possible in After Effects, such as GIF and MP4.</p>
                            <li>None</li>
                            <p>Selecting “None (metadata only)” will not render any images, but will still complete the generation process and output the metadata files and statistics.
                            You can use these metadata files at a later time to complete the image rendering if desired, by using the "Match Collection" button located in the "Extras" sections.</p>
                        </ul>
                    </div>
                </div>

                <div className={styles.article}>
                    <h1 id="linkedlayerprefs">Linked layers</h1>
                    <p>This section allows you to define how Selections inside Linked Layers match the parent Trait. There are 2 options:
                    </p>
                    <div className={styles.indented_area}>
                        <ul>
                             <li>Layer index</li>
                            <p>With this option selected, the Selections inside Linked layers will be chosen based on the layer order. 
                                If Selection layer #4 is chosen inside the Trait, then Selection layer #4 will be chosen inside the Linked Layer.
                                The naming of layers inside the Linked Layer is irrelevant.</p>
                            <li>Layer name</li>
                            <p>As you might expect, this method looks for a Selection layer inside the Linked Layer that has the same name as the chosen Selection inside the Trait.
                                This means the order of layers (or number of layers) doesn't matter as long as there is a matching name. If a matching name is not found then nothing
                                will be displayed.
                            </p>
                        </ul>
                    </div>
                </div>

                <div className={styles.article}>
                    <h1 id="generalprefs">General</h1>
                    <p>Some general settings:
                    </p>
                    <div className={styles.indented_area}>
                        <ul>
                             <li>Automatically set the Render Comp based on a provided name</li>
                            <p>This option lets you define a name for the Render Comp, and have this comp automatically be set each time Chump NFT Engine is opened.
                                If this option is checked, but you set the Render Comp using a different method then the name will be updated here.
                            </p>
                            <li>Automatically load the most recent project on startup</li>
                            <p>When you launch Chump NFT Engine, you can have the most recent project's info loaded automatically. If this is unchecked, you will need to
                                manually set the project again to continue working on it.
                            </p>
                        </ul>
                    </div>
                </div>

            </div>

            
    );
}
export default Preferences;    




