import React, { useMemo, useEffect, useRef, useState } from "react";
// import Navbar from "../../components/Header/Navbar";
import EnginePageContent from "../../../components/EnginePageContent";
import Layout from "../../../components/Layouts";
// import Footer from "../../components/Footer";

const LandingPage = () => {

    return (
        <div>
        <Layout >
            <EnginePageContent/>
        </Layout>
        </div>
    )
}
export default LandingPage    