
import React, { useMemo, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import global from './../../styles/global.module.scss';
import MainImage from './../../assets/images/Master_v2_noBG.gif';
import BannerImage from './../../assets/images/showreel_banner.png';
import SpecialTitle from "../SpecialTitle";

const LandingPageContent = () => {
    return (
        <div className={styles.main_content}>
          
          <div className={styles.title}><SpecialTitle>welcome frens.</SpecialTitle></div>
          <div className={styles.main_image}>
            <img src={MainImage}></img>
          </div>
          
          <div className={styles.description}>
              <span className={global.keyword}>Block Chumps</span> is an animation project created by veteran film/tv/dumb-stuff animator @SerChump.
          </div>
          <div className={styles.banner_image}>
            <img src={BannerImage}></img>
          </div>
          <div className={styles.description}>
              @SerChump has been a 2D & 3D animator for over 15 years with lead credits on some of the biggest animated feature films, while working for clients including Warner Bros, Paramount, HBO + more.
          </div>
          
          <div className={styles.description}>
              This project aims to lift the creative bar for animated NFT collections and provide tools for creators in the NFT space, starting with <a href="/chumpnftengine">Chump NFT Engine</a>.
          </div>

        </div>
    )
}
export default LandingPageContent;  