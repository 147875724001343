import React from "react";
import styles from './index.module.scss';

import LoginImg from './../../assets/images/docs/image9.png';
import CodeImg from './../../assets/images/docs/image4.png';
import MetadataImg from './../../assets/images/docs/image5.png';
import TemplateImg from './../../assets/images/docs/image8.png';
import ProjectImg from './../../assets/images/docs/image10.png';
import SpecialTitle2 from "../SpecialTitle2";

const GettingStarted = (props: any) => {

    return (
        <div className={styles.section_area} id="gettingstarted">
            <SpecialTitle2>
            Getting Started
                </SpecialTitle2>





            <div className={styles.article} id="installation">
                <h1>
                    Installation
                </h1>
                <p>
                    Unzip the archive and place the “chump_nft_engine.jsx” file into your After Effects “ScriptUI Panels” directory:
                </p>

                <div className={styles.indented_area}>
                    <h1>Windows:</h1>
                    <p>C:\Program Files\Adobe\Adobe After Effects (version)\Support Files\Scripts\ScriptUI Panels</p>
                </div>
                <div className={styles.indented_area}>
                    <h1>macOS:</h1>
                    <p>/Applications/Adobe After Effects (version)/Scripts/ScriptUI Panels</p>
                </div>
                <p>
                    After Effects will require a restart if it is currently running. Once restarted, you can find the extension at the bottom of the “Window” drop down.
                </p>
            </div>





            {/* 
           <div className={styles.article} id="loggingin">
                <h1>
                    Logging in (for the OG Chump version only)
                </h1>
                <p>
                When you first open CNE you’ll be prompted with a “login” window.
                </p>
                <div className={styles.image_area}>
                    <img src={LoginImg} alt="login" />
                    <div className={styles.image_area_description}>Login window</div>
                </div>
            
                <p>
                    You will need 3 things in order to launch the full version:
                </p>

                <div className={styles.indented_area}>
                    <ol>
                        <li>Hold a valid NFT (ie. an OG Chump)</li>
                        <p>If you did not mint an original, you can purchase one on a secondary market such as Magic Eden.</p>
                        <li>Have a verified role on the Chumpville Discord server</li>
                        <p>Once you have a valid NFT in your wallet, join the discord server and follow the instructions to get your verified role. This role will be checked constantly and removed if you no longer hold a valid NFT.</p>
                        <li>Have an activation code (provided by Discord)</li>
                        <p>This step can be performed from the login screen. Click the link to open Discord’s login verification and, if you hold a verified role, you will be granted an activation code. Once you have this, enter it where prompted. CNE will remember this code and use it to verify you each time you launch. The code may expire periodically, in which case you simply repeat this step.</p>
                    </ol>
                </div>

                <div className={styles.image_area}>
                    <img src={CodeImg} alt="code" />
                    <div className={styles.image_area_description}>After authorising/signing in through Discord, you’ll be redirected to this screen and provided a code.</div>
                </div>
                
            </div>
            */}
        </div>
    );
}
export default GettingStarted;    




