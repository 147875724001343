// components/layout.js

import { ReactNode } from "react";
import Footer from "../Footer";
import Navbar from "../Header/Navbar";
import styles from './index.module.scss';

interface Props {
    children?: ReactNode
    // any props that come into the component
}

export default function Layout({ children, ...Props }: Props) {
    
  return (
    <>
    <div className={styles.main_layout}>
      <Navbar />
      <div className={styles.content}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
    </>
  )
}