import React from "react";
import styles from './index.module.scss';

import TagImg from './../../assets/images/docs/tags.png';
import StructureImg from './../../assets/images/docs/structure.png';
import SpecialTitle2 from "../SpecialTitle2";

const Layers = (props: any) => {

    return (
        <div className={styles.section_area} id="layers">
            <SpecialTitle2>
                Layers & Tags
            </SpecialTitle2>

            <div className={styles.article }id="generalstructure">
                <h1>General structure</h1>
                <p>
                Layers account for everything in your collection. Everything you add to your project will be a layer of some kind, whether it be text, imported images, or Compositions. You can add anything you like in order to create the visuals you desire, as long as you follow some basic structure rules.
                </p>

                <div className={styles.indented_area}>
                <ol>
                    <li>All Traits must be descendants of the Render Comp.</li>
                    <p>This may seem like a no-brainer, but it's worth clarifying.</p>
                    <li>A Trait layer can be nested as long as it's not a descendant of a Selection layer.</li>
                    <p>ie. You can't nest a Trait inside a Selection.</p>
                    <li>A Selection layer must be directly inside a Trait layer to be valid.</li>
                    <p>You cannot nest Selection layers any deeper at this time.</p>
                    <li>A sub-Variant layer (optional) must be directly inside a Selection layer to be valid.</li>
                    <p>Sub-Variants are optional, additional variations added to each Selection.</p>
                </ol>
                </div>
                <p>
                Finally, you can add as many other "untagged" layers as you like to any location - they will simply be left as you intend them.
                </p>
                <p>A simple example of a valid structure is this:</p>

                <div className={styles.image_area}>
                    <img src={StructureImg} alt="structure" />
                </div>

                <div className={styles.tip_area}>
                    Use the ‘<a href="#layerstructuretemplate">Build Template</a>’ button in the <a href="#extrautilities">Extras</a> section to automatically create a complete template structure. You can then plug in your own artwork, rename layers, and add or remove what you don’t need.
                </div>


            </div>

            
            
            


            <div className={styles.article}>
            <h1>Layer tags</h1>
                    <p>
                        There are 4 tags that can be applied to layers in order to setup your collection. Simply select the layer, then click one of the following buttons to "tag" it.
                    </p>

                    <div className={styles.image_area}>
                        <img src={TagImg} alt="project" />
                        <div className={styles.image_area_description}>Layer tag section. Select a layer followed by one of these buttons to define your layers</div>
                    </div>

                    <p>Only 2 of these tags are required for creating a collection: The Trait tag & Selection tag. The other 2 tags are optional. Let's look closer:</p>
                    </div>
                    <div className={styles.article}>
                     <h1 id="traitlayers">
                        Trait
                    </h1>
                    <p>
                    A Trait is part of your collection that will change in appearance and provide uniqueness to your editions. In order for a Trait to be visible, it must have at least 1 Selection layer inside it. An example of a Trait is “Hairstyle”.
                    </p>
                    </div>
                    <div className={styles.article}>
                     <h1 id="selectionlayers">
                        Selection
                    </h1>
                    <p>
                    This layer, whether a composition itself (with the artwork inside) or simply a basic art layer, sits inside a Trait composition, and represents a single possible “selection” for the Trait. An example of a Selection is “Red Mohawk”, which would sit inside a Trait called “Hairstyle”.
                     </p>
                     </div>
                    <div className={styles.article}>
                     <h1 id="linkedlayers">
                        Linked
                    </h1>
                    <p>
                    The "Linked" tag is for creating multi-layer traits, where multiple layers are driven by a single Trait. Once clicked, you are prompted to select a Trait layer to act as the "parent". Any linked layers should contain Selection layers (just like a Trait), but these will mimic the Selections of the Trait they are linked to (either by name or by layer order).
                     </p>
                     </div>
                    <div className={styles.article}>
                     <h1 id="variantlayers">
                        Variant
                    </h1>
                    <p>
                    The Variant tag can be applied to layers inside a Selection layer. Variant layers work in conjunction with the "Trigger" rule that can be applied to a Selection. Once a Variant layer is tagged, it becomes available to the Trigger rule that can be set on any Selection in your project. Variant layers that are not triggered will be hidden, while Variant layers that are triggered will be displayed. An example use-case for Variant layers is to add different skin-tones variants to all Selections in all Traits, and then to have the appropriate skin tone triggered by adding a Trigger rule to the Selections in a Trait called "Skin Tone".
                     </p>
                     </div>
                    <div className={styles.article}>
                     <h1 id="untaggedlayers">
                        Untagged
                    </h1>
                    <p>
                    Remove the tag from a layer. Any untagged layer will be left as-is, which allows you to work with additional artwork, effects, masks etc. An example of an untagged layer is an Adjustment Layer that adds a colour effect, or a mask that hides part of a Trait, or a part of your edition artwork that never changes.
                    </p>
                    </div>

                    

            
        </div>
    );
}
export default Layers;    




